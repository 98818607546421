<template>
  <Form class="promocode-form" v-slot="{ invalid, changed }">
    <div class="grid-2">
      <FormItem rules="required" v-slot="{ isError }">
        <Input
          label="Code"
          v-model="form.code"
          placeholder="Code"
          :is-disabled="isDisabled"
          :is-error="isError"
        />
      </FormItem>
      <FormItem rules="required|numeric" v-slot="{ isError }">
        <Input
          label="Maximum number of uses"
          v-model="form.maxUseNumber"
          placeholder="Maximum number of uses"
          :is-disabled="isDisabled"
          :is-error="isError"
        />
      </FormItem>
      <FormItem rules="required|decimal:2" v-slot="{ isError }">
        <Input
          label="Discount"
          v-model="form.discount"
          placeholder="Discount"
          :is-disabled="isDisabled"
          :is-error="isError"
        />
      </FormItem>
      <FormItem rules="required" v-slot="{ isError }">
        <Select
          label="Discount type"
          v-model="form.discountType"
          placeholder="Discount type"
          :is-disabled="isDisabled"
          :options="discountTypeOptions"
          :is-error="isError"
        />
      </FormItem>
      <FormItem class="promocode-form__dates">
        <Input
          :value="dateRangeString"
          label="Validity dates"
          placeholder="Validity dates"
          :is-disabled="isDisabled"
          is-readonly
          @click.native="handleOpenDatepicker"
        />
        <Icon
          v-if="dateRange && dateRange.filter(Boolean).length"
          is-clickable
          name="close"
          color="secondary-400"
          class="promocode-form__dates__clear"
          @click="clearDates"
        />
        <DatePeriodModal
          v-model="dateRange"
          default-label="Set validity dates"
        />
      </FormItem>
      <FormItem rules="required" v-slot="{ isError }">
        <Select
          label="Tax behaviour"
          v-model="form.taxBehaviour"
          placeholder="Tax behaviour"
          :is-disabled="isDisabled"
          :options="taxBehaviourOptions"
          :is-error="isError"
        />
      </FormItem>
      <FormItem>
        <Select
          label="Products"
          v-model="form.products"
          placeholder="Products"
          :is-disabled="isDisabled"
          :options="productsOptions"
          is-multi
          :tags-limit="['xs'].includes($mq) ? 2 : 5"
        />
      </FormItem>
      <FormItem>
        <Select
          label="Upsell items"
          v-model="form.upsellItems"
          placeholder="UpsellItems"
          :is-disabled="isDisabled"
          :options="upsellItemsOptions"
          is-multi
          :tags-limit="['xs'].includes($mq) ? 2 : 5"
        />
      </FormItem>

      <FormItem class="grid-2__full-item" rules="required">
        <Switcher v-model="form.isActive" label="Active" />
      </FormItem>
    </div>
    <div class="promocode-form__buttons mt-auto">
      <Button
        v-if="!isDisabled"
        :is-block="['xs', 'sm'].includes($mq)"
        :isDisabled="invalid || !changed || isDeleting"
        :isLoading="isSubmitting"
        @click="handleSubmit"
      >
        {{ submitButtonText }}
      </Button>
      <Guard permission="promocodes.delete" v-slot="{ isAvailable }">
        <Button
          v-if="isAvailable && hasDeleteButton"
          :is-block="['xs', 'sm'].includes($mq)"
          isOutlined
          variant="danger"
          :isDisabled="isSubmitting"
          :isLoading="isDeleting"
          @click="remove"
        >
          Delete promocode
        </Button>
      </Guard>
    </div>
  </Form>
</template>

<script>
import Guard from "@/components/common/Guard.vue";
import { mapActions, mapState } from "vuex";
import dialog from "@/plugins/dialog";
import { PromoRateTypeEnum, TaxBehaviourEnum } from "@/helpers/enums";
import DatePeriodModal from "@/components/bookings/DatePeriodModal.vue";
import moment from "moment";

export default {
  name: "PromocodeForm",
  components: { DatePeriodModal, Guard },
  props: {
    value: {
      type: Object,
      required: true,
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    submitButtonText: {
      type: String,
      default: "Save",
    },
    hasDeleteButton: {
      type: Boolean,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        ...this.value,
      },
      isDeleting: false,
      discountTypeOptions: [
        {
          value: PromoRateTypeEnum.PERCENT,
          name: "Percent",
        },
        {
          value: PromoRateTypeEnum.FIXED,
          name: "Fixed",
        },
      ],
      taxBehaviourOptions: [
        {
          value: TaxBehaviourEnum.BEFORE,
          name: "Before taxes",
        },
        {
          value: TaxBehaviourEnum.AFTER,
          name: "After taxes",
        },
      ],
      dateRange: [],
    };
  },
  computed: {
    ...mapState({
      productsOptions: (state) =>
        state.products.list.map((product) => ({
          value: product.id,
          name: product.name,
        })),
      upsellItemsOptions: (state) =>
        state.upsellItems.list.map((item) => ({
          value: item.id,
          name: item.name,
        })),
      dateFormat: (state) => state.venues.selectedVenue?.dateFormat,
    }),
    dateRangeString() {
      const [from, to] = this.dateRange;
      return from || to
        ? [
            from ? moment(from).format(this.dateFormat) : "∞",
            to ? moment(to).format(this.dateFormat) : "∞",
          ].join(" - ")
        : "Permanent";
    },
  },
  async created() {
    this.dateRange = [this.form.from, this.form.to];
    await Promise.allSettled([this.fetchProducts(), this.fetchUpsellItems()]);
  },
  methods: {
    ...mapActions({
      fetchProducts: "products/fetchProducts",
      fetchUpsellItems: "upsellItems/fetchUpsellItems",
      deletePromocode: "promocodes/deletePromocode",
    }),
    async remove() {
      const confirmed = await dialog.confirm({
        title: "Delete Promocode?",
        message: "All promocode data will be lost",
        okText: "Delete",
        cancelText: "Cancel",
      });
      if (confirmed) {
        try {
          this.isDeleting = true;
          await this.deletePromocode(this.$route.params.id);
          await this.$router.push({
            name: "Promocodes",
          });
        } finally {
          this.isDeleting = false;
        }
      }
    },
    handleOpenDatepicker() {
      this.$modal.show("datePeriodModal");
    },
    handleSubmit() {
      const [from = "", to = ""] = this.dateRange;
      this.$emit("submit", {
        ...this.form,
        maxUseNumber: +this.form.maxUseNumber,
        discount: +this.form.discount,
        from,
        to,
      });
    },
    clearDates() {
      this.dateRange = [];
    },
  },
};
</script>

<style scoped lang="scss">
.promocode-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 64px;
  height: 100%;

  &__dates {
    position: relative;

    &__clear {
      position: absolute;
      right: 12px;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 11px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (min-width: $media-laptop) {
      flex-direction: row;
      gap: 24px;
    }
  }
}
</style>
