<template>
  <CustomModal
    :name="modalName"
    :classes="['date-range-picker__modal']"
    is-scrollable
    @before-close="resetState"
  >
    <template v-slot:header>
      <Title :level="2" class="date-range-picker__modal__title">
        {{ defaultLabel }}
      </Title>
    </template>
    <CalendarView
      variant="secondary"
      :date="innerValue"
      is-range
      :is-dates-before-today-blocked="false"
      class="date-range-picker__modal__calendar"
      @selectDate="handleDateSelect"
    />
    <div v-if="rangeButtons.length" class="date-range-picker__modal__buttons">
      <button
        v-for="button in rangeButtons"
        :key="button.value"
        :class="[
          'date-range-picker__modal__button',
          {
            'm-active': selectedRange === button.value,
          },
        ]"
        @click="$emit('range-button-click', button.value)"
      >
        {{ button.name }}
      </button>
    </div>
    <div class="date-range-picker__modal__actions">
      <Button is-block :is-disabled="isInvalid" @click="setDates">
        Set dates
      </Button>
      <Button is-block isOutlined class="m-white" @click="cancel">
        Cancel
      </Button>
    </div>
  </CustomModal>
</template>

<script>
import moment from "moment";
import { Components } from "@zteam/booking-axe-sb";

export default {
  name: "DatePeriodModal",
  components: {
    CalendarView: Components.CalendarView,
  },
  props: {
    value: {
      type: Array,
      default: () => [null, null],
    },
    defaultLabel: {
      type: String,
      default: "Set event's period",
    },
    format: {
      type: String,
      default: "MM.DD.YYYY",
    },
    rangeButtons: {
      type: Array,
      default: () => [],
    },
    selectedRange: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      modalName: "datePeriodModal",
      innerValue: [null, null],
    };
  },
  watch: {
    value() {
      this.resetState();
    },
  },
  computed: {
    iconColor() {
      return this.value.some((item) => !!item) ? "primary" : "secondary-500";
    },
    label() {
      if (this.value.some((item) => !!item)) {
        const [start, end] = this.value;
        return [
          start && `${moment(start).format(this.format)}`,
          end && `${moment(end).format(this.format)}`,
        ]
          .filter(Boolean)
          .join(" - ");
      }
      return "";
    },
    isInvalid() {
      return !this.innerValue.some((item) => !!item);
    },
  },
  created() {
    this.resetState();
  },
  methods: {
    setDates() {
      const [start, end] = this.innerValue;
      const emittedValue = [
        start &&
          moment(new Date(start.year, start.month, start.date)).format(
            "YYYY-MM-DD"
          ),
        end &&
          moment(new Date(end.year, end.month, end.date)).format("YYYY-MM-DD"),
      ];
      this.$emit("input", emittedValue);
      this.$modal.hide(this.modalName);
    },
    handleDateSelect(dates) {
      this.$emit("set-current-first-date", dates[0]);
      this.$emit("reset-selected-range", null);
      this.innerValue = dates;
    },
    resetState() {
      const [start, end] = this.value;
      const startMoment = start && moment(start);
      const endMoment = end && moment(end);
      this.innerValue = [
        startMoment && {
          year: startMoment.year(),
          month: startMoment.month(),
          date: startMoment.date(),
        },
        endMoment && {
          year: endMoment.year(),
          month: endMoment.month(),
          date: endMoment.date(),
        },
      ];
    },
    clear() {
      this.$emit("reset-selected-range", null);
      this.$emit("set-current-first-date", null);
      this.$emit("input", [null, null]);
    },
    cancel() {
      this.resetState();
      this.$modal.hide(this.modalName);
    },
  }
}
</script>

<style lang="scss">
.vm--modal.date-range-picker__modal {
  height: max-content !important;
}

.date-range-picker__modal {
  padding: 40px 0 48px;
  background-color: $secondary-500;

  &__title {
    text-align: center;
    color: $white;
  }

  &__calendar {
    box-shadow: none !important;
    border: none;
    width: 100%;
    max-width: 100% !important;
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 0 16px;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    margin-bottom: 40px;
    padding: 0 24px;
  }

  &__button {
    padding: 3px 16px;
    background: rgba(194, 206, 227, 0.06);
    border: 2px solid #384966;
    border-radius: 6px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $white;
    cursor: pointer;
    transition: 0.3s;

    &.m-active {
      background: $primary;
    }
  }
}
</style>
